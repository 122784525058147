<template>
	<div>
		<div>
			<div class="form-search-box">
				<a-form layout="inline" >
					<a-form-item label="客服名称">
						<a-input v-model="search.name" placeholder="请输入客服名称"></a-input>
					</a-form-item>

					<a-form-item label="客服手机号">
						<a-input v-model="search.mobile" placeholder="请输入手机号"></a-input>
					</a-form-item>
					
					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
						<a-button class="ml10" type="primary" icon="plus" @click="addKefuAct()">添加客服</a-button>
					</a-form-item>
				</a-form>
			</div>
		</div>

		<div class="mt20 bg-w">
			<div class="wxb-table-white mt10">
				<a-table rowKey="customer_service_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
				 >
					<div class="flex center text-center" slot="qrcode" slot-scope="qrcode,record">
						<img v-if="qrcode != null" :src="qrcode" style="width: 40px; height: 40px;"/>
						<img v-else src="../../assets/image/kefu.png" style="width: 40px; height: 40px;"/>
					</div>
				 
					<template slot="action" slot-scope="text,record">
						<div class="flex center">
							<a-dropdown placement="bottomRight">
								<span class="more-act">
									<i class="iconfont iconmore_gray"></i>
								</span>
								<a-menu slot="overlay">
									<a-menu-item>
										<a class="menu-act" href="javascript:;" @click="editKefuAct(record)">
											<i class="iconfont ft14 iconedit"></i>
											<span class="ml10">编辑客服</span>
										</a>
									</a-menu-item>
									<a-menu-item>
										<a class="menu-act" href="javascript:;" @click="delKefuAct(record)">
											<i class="iconfont ft14 icondelete"></i>
											<span class="ml10">删除客服</span>
										</a>
									</a-menu-item>
								</a-menu>
							</a-dropdown>
						</div>
					</template>
				</a-table>
			</div>
		</div>
		
		
		<div v-if="editKefuLoading">
			<edit-kefu :customer_service_id="customer_service_id" :visible="editKefuLoading" @cancel="cancelEditKefu" @ok="okEditKefu"></edit-kefu>
		</div>
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../common/mixin/list.js';
	import editKefu from './components/kefu/modal/editKefu.vue';
	export default {
		mixins: [listMixin],
		components:{
			editKefu,
		},
		
		mounted(){
			document.querySelector('.ant-table').style="height:calc(100vh - 350px)"
		},
		data() {
			return {
				loading: false,
				editKefuLoading:false,
				customer_service_id:0,
				pagination: {
					current: 1,
					pageSize: 50, //每页中显示10条数据
					total: 0,
				},
				search: {
					name: '',
					mobile: "",
				},
				columns: [
					{title: 'ID编号',dataIndex: 'customer_service_id',align: 'center',ellipsis: true},
					{title: '客服名称',dataIndex: 'name',align: 'center',ellipsis: true},
					{title: '客服二维码',dataIndex: 'qrcode',align: 'center',scopedSlots: {customRender: 'qrcode'}},
					{title: '客服手机号',dataIndex: 'mobile',align: 'center',ellipsis: true},
					{title: '添加时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}},
					
				],
				datas: [],
			}
		},
		methods: {
			
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/admin/getKefuList',{
					name:this.search.name,
					mobile:this.search.mobile,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.datas=res.list;
					this.pagination.total=res.total;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			
			
			addKefuAct(){
				this.customer_service_id=0;
				this.editKefuLoading=true;
			},
			editKefuAct(record){
				this.customer_service_id=record.customer_service_id;
				this.editKefuLoading=true;
			},
			cancelEditKefu(){
				this.editKefuLoading=false;
			},
			okEditKefu(){
				this.editKefuLoading=false;
				this.getLists();
			},
			
			delKefuAct(record){
				this.$confirm({
					title:'确认删除这个客服吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('platform/admin/delKefu',{
								customer_service_id:record.customer_service_id,
							}).then(res=>{
								this.$message.success('删除成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			}
		}
	}
</script>

<style>
	
</style>
